import React from "react";
import Jason from "../images/Team/jason_edited.jpg";
import Raneshia from "../images/Team/lawrence_edited.jpg";
import Josh from "../images/Team/josh_edited.jpg";
import Burger from "/src/components/Burger/Burger";
import Logo from "../images/Hyloq/Hyloq-white-logo (2).svg";
import FooterLogo from "../images/Hyloq/hyloq-logo-blue.svg";
import { Helmet } from "react-helmet";

const Company = () => (
  <div>
    <Helmet>
      <title>Hyloq</title>
      <link
        rel="icon"
        type="image/x-icon"
        href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
      ></link>
      <link
        data-react-helmet="true"
        rel="canonical"
        href="https://hyloq.com/"
      ></link>
      <meta data-react-helmet="true" charset="utf-8"></meta>
      <meta
        data-react-helmet="true"
        name="description"
        content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
      ></meta>
      <meta
        data-react-helmet="true"
        name="robots"
        contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:locale"
        content="en-US"
      ></meta>
      <meta data-react-helmet="true" property="og:type" content="page"></meta>
      <meta
        data-react-helmet="true"
        property="og:title"
        content="Hyloq | True-Scope Geofencing Advertising Platform"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:description"
        content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:url"
        content="https://hyloq.com/"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:site_name"
        content="Hyloq | True-Scope Geofencing Advertising Platform"
      ></meta>
      <meta
        data-react-helmet="true"
        property="article:tag"
        content="geofencing , geofencing advertising , true-scrope"
      ></meta>
      <meta
        data-react-helmet="true"
        property="face-domain-verification"
        content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
      ></meta>
      <link
        href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
        rel="stylesheet"
      ></link>
      <script
        src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
        async
      ></script>
    </Helmet>
    <title>Hyloq | Our Team</title>
    <link
      rel="icon"
      type="image/x-icon"
      href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
    ></link>
    <header class="bg-gradient-to-l from-secondaryBlue to-primaryBlue">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
        <div class="w-full py-4 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div class="flex items-center">
            <a href="/">
              <span class="sr-only">Hyloq</span>
              <img class="h-20 w-48" src={Logo} alt="" />
            </a>
          </div>

          <button
            type="button"
            class="ml-5 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <Burger />
          </button>
        </div>{" "}
      </nav>
    </header>

    <div class="shapedividers_com-1069 bg-white">
      <div class="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-12 lg:pb-32">
        <div class="space-y-12">
          <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 class="pt-10 md:pt-0 text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
              Our Team
            </h2>

            <p class="mt-4 max-w-4xl mx-auto text-center md:text-xl leading-7 text-gray-500">
              The staff at Hyloq are dedicated to the continued development of
              our platform and the success it brings to our customers. Hyloq was
              developed to serve a portion of the Programmatic geofence market
              and is the 1st True-scope geofence platform on the market.
              Currently most Ad Tech companies charge large CPM/OTT fees (e.g.
              $30,000 mo. minimum) just to gain access to their platforms. This
              leaves out large portions of the marketplace that want this
              service but can't afford the +/- $360K per-year investment. By
              offering Hyloq as a SaaS model we're able to reduce their costs
              significantly and offer an unparalleled customer blueprint than is
              currently available by competitors.
            </p>
            <br />
          </div>
          <ul
            role="list"
            class="space-y-4 grid sm:gap-6 sm:space-y-0 md:grid-cols-3 lg:gap-8"
          >
            <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Jason}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-slate-700 text-center text-lg">
                      Jason Stanard
                    </h3>
                    <p class="text-red-600 text-center font-semibold">
                      Owner/President
                    </p>
                    <p class="text-gray-500 text-center font-light text-base">
                      Mr. Stanard has over 20 years of experience in the online
                      technology field. A serial entrepreneur, he is an expert
                      on branding, sales, and marketing. He has been featured on
                      radio shows and in trade publications as an expert in the
                      field of online sales and marketing.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Raneshia}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-slate-700 text-center text-lg">
                      Raneshia Lawrence
                    </h3>
                    <p class="text-red-600 text-center font-semibold">
                      VP/Marketing Director
                    </p>
                    <p class="text-gray-500 text-center font-light text-base">
                      Raneshia oversees all digital branding. Raneshia graduated
                      Magna Cum Laude from the University of South Florida with
                      B.A. in Mass Communications & Advertising. She has worked
                      in digital marketing for 10 years, with a focus on online
                      marketing and branding strategy.
                    </p>
                  </div>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-white text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Josh}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-slate-700 text-center text-lg">
                      Joshua Sutherland
                    </h3>
                    <p class="text-red-600 text-center font-semibold">
                      Creative Director
                    </p>
                    <p class="text-gray-500 text-center font-light text-base pb-10 md:pb-0">
                      Previously a senior graphic designer at Tech Data, a web
                      designer at Social Media Today, and a graphic designer for
                      Seminole Boosters Inc., Sutherland has 8+ years of
                      experience in the field. He is a top 3 award winner for
                      CCYS Tallahassee for best design business (2017 & 2018).
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer class="bg-gray-100 py-3 border-t border-gray-100">
      <div class="max-w-7xl mx-auto flex justify-between md:gap-0 md:p-4 p-2 gap-4">
        <div class="">
          <img class="h-16 w-auto" src={FooterLogo} alt="" />
          <h1 class="text-center text-xl tracking-wide font-medium text-gray-700 md:block hidden">
            Stand out in the noise.
          </h1>
        </div>

        <div class="flex items-center justify-center">
          <div class="relative">
            <h1 class="text-xl tracking-wide font-medium md:text-base text-sm text-gray-700">
              <a href="#" class="hover:opacity-80 hover:underline">
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <a href="mailto:email@example.com">
                    <h1> marketing@hyloq.com</h1>
                  </a>
                </div>
              </a>
              <br />
              <a href="#" class="hover:opacity-80 hover:underline">
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700 ">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.59 1.322l2.844-1.322 4.041 7.89-2.725 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.861 3.591-19.101-18.258-11.384-22.281zm1.93 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398zm7.832 7.649l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.059c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.186c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <a href="tel:813-597-9187">
                    <h1> +1 (813) 597-9187</h1>
                  </a>
                </div>
              </a>
            </h1>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto border-t border-white"></div>

      <div class="copyright py-5 max-w-7xl mx-auto px-4 sm:px-6 flex items-center justify-center md:justify-between lg:px-8">
        <div class="flex justify-center space-x-2 order-2">
          <h1 class="text-xl tracking-wide font-medium text-base text-gray-700 md:block hidden">
            <a class="hover:underline" href="/contact">
              Contact Us
            </a>
          </h1>
          <h1 class="text-xl tracking-wide font-medium text-base text-gray-700 md:block hidden">
            <a class="hover:underline" href="/Team">
              Team
            </a>
          </h1>

          <a
            href="https://www.facebook.com/Hyloq/"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only ">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/hyloq"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only">Linkedin</span>
            <svg
              class="h-6 w-6 pt-0.5 pb-0.5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>

        <div class="mt-0 md:order-1">
          <p class="text-center md:text-base text-sm tracking-wide font-medium text-gray-700">
            &copy; {new Date().getFullYear()} Hyloq, LLC.
            <a
              class="text-primaryBlue hover:underline"
              href="/capabilities"
            ></a>{" "}
          </p>
        </div>
      </div>
    </footer>
  </div>
);

export default Company;
